import React from 'react';

const PendulumWaves = () => (
  <section className="pendulum-waves">
    <ul className="pendulum-waves__container">
      <li className="pendulum-waves__arm pendulum-waves__arm--1" />
      <li className="pendulum-waves__arm pendulum-waves__arm--2" />
      <li className="pendulum-waves__arm pendulum-waves__arm--3" />
      <li className="pendulum-waves__arm pendulum-waves__arm--4" />
      <li className="pendulum-waves__arm pendulum-waves__arm--5" />
      <li className="pendulum-waves__arm pendulum-waves__arm--6" />
      <li className="pendulum-waves__arm pendulum-waves__arm--7" />
      <li className="pendulum-waves__arm pendulum-waves__arm--8" />
      <li className="pendulum-waves__arm pendulum-waves__arm--9" />
      <li className="pendulum-waves__arm pendulum-waves__arm--10" />
    </ul>
  </section>
);

export default PendulumWaves;
