import React from 'react';

const SocialLinks = () => (
  <div className="social__container">
    <a
      className="social__link social__link--github"
      target="_blank"
      rel="noopener noreferrer"
      href="https://github.com/ianwessen/">
      <i className="fab fa-github" /> Github
    </a>
    <a
      className="social__link social__link--linkedin"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.linkedin.com/in/ian-wessen-36bab869/">
      <i className="fab fa-linkedin" /> LinkedIn
    </a>
    <a
      className="social__link social__link--dribbble"
      target="_blank"
      rel="noopener noreferrer"
      href="https://dribbble.com/ianwessen">
      <i className="fab fa-dribbble" /> Dribbble
    </a>
    <a
      className="social__link social__link--spotify"
      target="_blank"
      rel="noopener noreferrer"
      href="https://open.spotify.com/user/ianwessen">
      <i className="fab fa-spotify" /> Spotify
    </a>
    <a
      className="social__link social__link--codepen"
      target="_blank"
      rel="noopener noreferrer"
      href="https://codepen.io/ianwessen/">
      <i className="fab fa-codepen" /> Codepen
    </a>
  </div>
);

export default SocialLinks;
