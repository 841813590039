import React from 'react';
import { graphql } from 'gatsby';

import PendulumWaves from '../components/PendulumWaves';
import SocialLinks from '../components/SocialLinks';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <>
        <div className="page page-home container-reading">
          <div className="page-home__row">
            <div className="page-home__pendulum-container">
              <PendulumWaves />
            </div>
            <div className="page-home__title-container">
              <h1>Ian Wessen</h1>
              <p>
                I’m something of a writer, but only web browsers read my work.
              </p>
            </div>
          </div>
          <SocialLinks />
        </div>
      </>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
